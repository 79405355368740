/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import "assets/css/bootstrap.css";
// @import "assets/css/bootstrap.min.css";
@import "assets/css/styles.css";
@import "assets/css/mystyles.css";
@import "assets/css/mobile.css";
//@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';
@import "assets/css/all.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";


